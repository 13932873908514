<template>
  <el-dialog
    :title="
      '正确导入' +
      (errorMsg.total - errorMsg.errorNumber) +
      '条，错误信息' +
      errorMsg.errorNumber +
      '条'
    "
    :visible.sync="visible"
    :before-close="close"
    width="1500px"
  >
    <el-table :data="errTableList" style="width: 100%">
      <el-table-column type="index" label="序号" width="55" align="center">
      </el-table-column>
      <template v-for="item in [...errCloumn, ...errorCloumn]">
        <el-table-column
          v-if="item.range"
          :key="item.prop"
          :min-width="item.width"
          :label="item.label"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-date-picker
              v-if="editErr"
              style="width: 100%"
              v-model="scope.row[item.prop]"
              type="daterange"
              range-separator="至"
              value-format="yyyy-MM-dd HH:mm:ss"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
            <span v-else>{{
              scope.row[item.prop]
                .map((it) => dateFormat("YYYY-mm-dd HH:MM:SS", it))
                .join("至")
            }}</span>
          </template>
        </el-table-column>

        <el-table-column
          v-else
          :key="item.prop"
          :prop="item.prop"
          :min-width="item.width"
          :label="item.label"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-input
              v-if="editErr && item.prop != 'reason'"
              size="mini"
              v-model="scope.row[item.prop]"
            />
            <span v-else :style="item.prop == 'reason' ? 'color:red' : ''">{{
              scope.row[item.prop]
            }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <div slot="footer" class="d-f jc-c ai-c">
      <el-button @click="close">取消</el-button>
      <el-button v-if="!editErr" @click="editErr = !editErr"
        >修改错误信息</el-button
      >
      <el-button v-else @click="editErrFunc">确认修改</el-button>
      <el-button @click="reImport">重新导入</el-button>
      <el-button type="primary" @click="exportErrMessage">导出信息</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "error-excel-dialog",
  data() {
    return {
      visible: false,
      editErr: false,
      errorMsg: {},
      errTableList: [],
      uuid: "",
      errorCloumn: [{ prop: "reason", label: "错误原因", width: "300" }],
    };
  },
  props: {
    errCloumn: {
      type: Array,
      default: () => [],
    },
    batAddFunction: Function,
    getTableData: Function,
    listTransform: Function,
  },
  methods: {
    dateFormat(fmt, date) {
      let ret;
      const opt = {
        "Y+": date.getFullYear().toString(), // 年
        "m+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时
        "M+": date.getMinutes().toString(), // 分
        "S+": date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      };
      for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
          fmt = fmt.replace(
            ret[1],
            ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
          );
        }
      }
      return fmt;
    },
    close() {
      this.visible = false;
    },
    reImport() {
      this.close();
      this.$emit("reImport");
    },
    editErrFunc() {
      this.batAddFunction &&
        this.batAddFunction(this.errTableList).then((res) => {
          if (res.length) {
            this.errTableList = res;
            this.editErr = !this.editErr;
          } else {
            this.$message.success("批量修改成功！");
            this.errTableList = [];
            this.visible = false;
          }
        });
    },
    exportErrMessage() {
      if (this.uuid) {
        this.$emit("exportErrMessage", this.uuid);
      }
    },
    getError(err) {
      console.log(err);
      const result = err.data.data;
      this.uuid = result.uuid;
      this.errorMsg = {
        total: result.listExcels.length,
        errorNumber: Object.keys(result.errorMap).length,
      };
      const list = result.listExcels.map((it) => ({ ...it, reason: [] }));
      Object.values(result.errorMap).forEach((yy) => {
        yy.forEach((r) => {
          const item = list[r.row];
          item.reason.push(r.errorMsg);
        });
      });
      console.log(list);
      const transform =
        this.listTransform ||
        function (item) {
          return item;
        };
      this.errTableList = transform(
        list
          .filter((it) => it.reason && it.reason.length)
          .map((it) => ({
            ...it,
            reason: it.reason.join(","),
          }))
      );
      this.visible = true;
    },
  },
  watch: {
    visible(val) {
      if (!val) {
        this.editErr = false;
        this.getTableData && this.getTableData();
      }
    },
  },
};
</script>

<style>
</style>