<template>
  <el-drawer
    :wrapperClosable="false"
    custom-class="room-add-room"
    :title="title || '编辑'"
    :visible="drawer"
    :before-close="close"
    :direction="'rtl'"
    :size="400"
  >
    <div class="add-room-body">
      <div class="add-room-title require">姓名</div>
      <my-input v-model="whiteParams.name" placeholder="请填写姓名" />
      <div class="add-room-title require">手机号码</div>
      <el-input v-model="whiteParams.phone" placeholder="请填写手机号码" />
      <div class="add-room-title require">学校名称</div>
      <el-select
        style="width: 100%"
        v-model="whiteParams.universityId"
        placeholder="请选择"
      >
        <el-option
          v-for="item in schoolList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>

      <div class="add-room-title require">起止时间</div>
      <el-date-picker
        style="width: 100%"
        v-model="whiteParams.rangeTime"
        type="daterange"
        range-separator="至"
        value-format="yyyy-MM-dd HH:mm:ss"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      >
      </el-date-picker>

      <div class="add-room-title">添加原因</div>
      <el-input v-model="whiteParams.reason" placeholder="请输入添加原因" />
    </div>
    <div class="add-room-submit w-100_ d-f ai-c">
      <el-button type="primary" @click="addRoomFunc">保 存</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-drawer>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
const { mapState } = createNamespacedHelpers("white");
export default {
  name: "school-params-change",
  data() {
    return {
      drawer: false, //  抽屉
      whiteParams: {
        universityName: "",
        name: "", //
        phone: "",
        universityId: "",
        rangeTime: [],
        reason: "",
      },
      loading: false,
    };
  },
  methods: {
    close() {
      this.$emit("update:visible", false);
    },
    addRoomFunc() {
      const warning = {
        name: "请输入姓名",
        phone: "请输入手机号码",
        universityId: "请输入学校",
      };
      // if (!this.isModify) {
      //   warning.password = "请输入管理员密码";
      // }
      const list = Object.keys(warning);
      let canIgo = true;
      for (let i = 0; i < list.length; i++) {
        if (!String(this.whiteParams[list[i]])) {
          this.$message.error(warning[list[i]]);
          canIgo = false;
          break;
        }
      }
      if (
        !/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
          this.whiteParams.phone
        )
      ) {
        this.$message.error("请输入正确的手机号");
        return;
      }
      if (this.whiteParams.rangeTime.length != 2) {
        this.$message.error("请选择起始时间");
        return;
      }
      if (canIgo) {
        this.close();
        const params = { ...this.whiteParams };
        params.startTime = params.rangeTime[0] || "";
        params.endTime = params.rangeTime[1] || "";
        delete params.rangeTime;
        this.$emit("submit", params);
      }
    },
  },
  watch: {
    "whiteParams.universityId"(val) {
      for (let item of this.schoolList) {
        if (item.id == val) {
          console.log(item);
          this.whiteParams.universityName = item.name;
          break;
        }
      }
    },
    drawer() {
      this.whiteParams = {
        universityName: "",
        name: "", //
        phone: "",
        universityId: "",
        rangeTime: [],
        reason: "",
      };
    },
    visible(val) {
      this.drawer = val;
    },
    data(val) {
      this.whiteParams = val;
    },
  },
  props: {
    visible: Boolean,
    title: String,
    data: Object,
  },
  computed: {
    ...mapState(["schoolList"]),
  },
};
</script>

<style lang="scss" scoped>
.room-add-room {
  .add-room-body {
    padding: 0 24px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    height: calc(100% - 56px);
    .add-room-title {
      line-height: 50px;
      color: #262626;
      font-weight: bold;
    }
  }
  .add-room-submit {
    bottom: 0;
    height: 56px;
    background: #fbfbfb;
    padding: 0 24px;
  }
}
</style>