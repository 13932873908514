<template>
  <div class="h-100_">
    <content-header title="白名单管理">
      <div class="white">
        <el-button @click="importVisible = true">导入</el-button>
        <el-button @click="exportData(true)">全部导出</el-button>

        <el-button
          @click="exportData(false)"
          :disabled="!(selection && selection.length)"
          >导出</el-button
        >

        <el-button type="primary" @click="addVisible = true">添加</el-button>
      </div>
    </content-header>
    <content-table
      ref="table"
      :total="total"
      :columns="columns"
      :setting="setting"
      :getTableData="getTableData"
      :tableList="tableList"
      @delete="deleteItem"
      @edit-outline="edit"
      needSelection
      @selectionChange="selectionChange"
    ></content-table>
    <white-drawer
      title="添加白名单"
      :visible.sync="addVisible"
      @submit="submit"
    ></white-drawer>
    <white-drawer
      title="编辑白名单"
      :visible.sync="editVisible"
      :data="editData"
      @submit="editSure"
    ></white-drawer>
    <el-dialog
      custom-class="room-header-add-a"
      title="批量导入"
      :visible.sync="importVisible"
      width="360px"
      style="padding: 0"
    >
      <el-alert
        v-if="filesName"
        :title="filesName"
        type="success"
        effect="dark"
        @close="filesName = ''"
      >
      </el-alert>
      <div v-else class="d-f ai-c" style="flex-direction: column">
        <el-button @click="downloadTemplate" type="text" style="width: auto"
          >点击下载白名单管理模板</el-button
        >

        <el-button style="margin: 5px auto" @click="$refs.file.click()"
          ><img style="width: 10px" src="@/assets/upload.png" alt="" />
          上传文件</el-button
        >
        <input
          @change="fileChange"
          ref="file"
          type="file"
          style="display: block; width: 1px; height: 0; opacity: 0"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />

        <div slot="tip" class="f-12 c-9 ta-c">支持扩展名：xlsx</div>
      </div>
      <div slot="footer" class="d-f jc-c ai-c">
        <el-button @click="importVisible = false">取消</el-button>
        <el-button type="primary" @click="sure">确认</el-button>
      </div>
    </el-dialog>
    <error-excel
      ref="err"
      @reImport="importVisible = true"
      @exportErrMessage="exportErrMessage"
      :errCloumn="columns.filter((it) => it.prop != 'createTime')"
      :batAddFunction="batAddWhite"
      :getTableData="getTableData"
      :listTransform="listTransform"
    ></error-excel>
  </div>
</template>

<script>
import ContentHeader from "@/components/contentHeader.vue";
import ContentTable from "@/components/contentTable.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("white");
import whiteDrawer from "./drawer.vue";
import ErrorExcel from "./errorExcel.vue";
export default {
  name: "school",
  components: {
    ContentHeader,
    ContentTable,
    whiteDrawer,
    ErrorExcel,
  },
  data() {
    return {
      errVisible: false,
      uuid: "",
      errTableList: [],
      errorMsg: {},

      columns: [
        { label: "姓名", prop: "name", width: "80" },
        { label: "手机号", prop: "phone", width: "120" },
        { label: "学校名称", prop: "universityName", width: "120" },
        { label: "创建时间", prop: "createTime", width: "200" },
        {
          label: "起止时间",
          prop: "times",
          range: ["startTime", "endTime"],
        },
        // { label: "添加原因", prop: "reason" },
      ],
      setting: ["edit-outline", "delete"],
      addVisible: false,
      editVisible: false,
      editData: null,
      importVisible: false,
      filesName: "",
      File: null,
      selection: [],
    };
  },
  methods: {
    ...mapActions([
      "getTableData",
      "addTableData",
      "modifyTableData",
      "deleteTableData",
      "getAllSchoolList",
      "uploadExcelTemplate",
      "getExcel",
      "getExcelTemplate",
      "getErrWhiteExcel",
      "batAddWhite",
    ]),
    listTransform(list) {
      return list.map((it) => ({
        ...it,
        times: [new Date(it.startTime), new Date(it.endTime)],
      }));
    },
    getTableDataList(val) {
      this.getTableData(val);
    },
    submit(val) {
      this.addTableData(val);
    },
    deleteItem(val) {
      //  删除改行
      this.$confirm("确定要删除【" + val.name + "】这条数据吗？", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.deleteTableData(val.id);
      });
    },
    edit(val) {
      //  编辑该行
      this.editData = {
        ...val,
        rangeTime: [val.startTime, val.endTime].filter(Boolean),
      };
      this.editVisible = true;
    },
    editSure(val) {
      this.modifyTableData(val).then(() =>
        this.$message.success("修改白名单成功")
      );
    },
    fileChange(e) {
      const file = e.target.files[0];
      this.filesName = file.name;
      this.File = file;
    },
    downloadTemplate() {
      this.getExcelTemplate();
    },
    sure() {
      const fd = new FormData();
      fd.append("file", this.File);
      this.uploadExcelTemplate(fd).catch((err) => {
        console.log(err);
        this.$refs.err.getError(err);
      });
      this.importVisible = false;
    },
    selectionChange(val) {
      console.log(val);
      this.selection = val;
    },
    exportData(all = false) {
      this.getExcel({
        exportIds: all ? 0 : this.selection.map((it) => it.id).join(","),
      });
    },
    exportErrMessage(uuid) {
      this.getErrWhiteExcel({ uuid });
    },
  },
  mounted() {
    this.$refs.table.getDataInit();
    this.getAllSchoolList();
  },
  computed: {
    ...mapState(["total", "tableList"]),
  },
  watch: {
    importVisible() {
      this.filesName = "";
    },
  },
};
</script>
<style lang="scss" scoped>
.white {
  button {
    min-width: 76px;
  }
}
</style>